/**
 * Set __smbChannelSrc cookie based on url parameters
 * @version 1.0.0
 * @author Erik Ondrus <erik.ondrus@avast.com>
 * @requires avast.storage.cookieStorage
 * @requires element with id "smb-cm-channel-utm-map" and "data-cm-map"
 * @requires element with id "smb-cm-channel-cookie-map" and "data-cm-map"
 */

(function() {
	'use strict';

	var cookieStorage = avm.require('avast.storage.cookieStorage'),
		locationHref = window.location.href,
		expirationInMinutes = 30,
		CM_CHANNEL_COOKIE = '__smbChannelSrc',
		//"Cannot read properties of null" Error here is intentional to alert you to missing data
		cmChannelUtmMap = JSON.parse(document.getElementById('smb-cm-channel-utm-map').dataset.cmMap),
		cmChannelCookieMap = JSON.parse(document.getElementById('smb-cm-channel-cookie-map').dataset.cmMap),
		cmChannelValue;

	/*
	 * @param {object} - list of CMs {'marker': ['parameter=value']}
	 * @param {string} - window.location.Href
	 * @returns {string}
	 */
	var getMarkerBasedOnUtmList = function(listOfCm, locationHref) {
		var marker = Object.keys(listOfCm).filter(function(key) {
			return listOfCm[key].every(function(item) {
				return locationHref.indexOf(item) > -1;
			});
		});
		return marker.join('-');
	};
	
	/*
	 * @param {object} - list of CMs {'marker': ['cookieName']}	 
	 * @returns {string}
	 */
	var getMarkerBasedOnCookieList = function(listOfCm) {
		var marker = Object.keys(listOfCm).filter(function(key) {
			return listOfCm[key].every(function(item) {
				return cookieStorage.getItem(item);
			});
		});		
		return marker.join('-');
	};	

	/*
	 * @param {number} - minutes
	 * @returns {UTCString}
	 */
	var getExpirationTime = function(minutes) {
		var now = new Date();
		now.setTime(now.getTime() + minutes * 60 * 1000);
		return now.toUTCString();
	};

	/*
	 * Set Cookie with smbChannelSrc
	 */
	var cmFromCookie = getMarkerBasedOnCookieList(cmChannelCookieMap);
	var cmFromUtm = getMarkerBasedOnUtmList(cmChannelUtmMap, locationHref);

	cmChannelValue = cmFromUtm || cmFromCookie // the utm value is prioritized
	
	if (cmChannelValue && cmChannelValue.length > 0) {
		cookieStorage.setItem(CM_CHANNEL_COOKIE, cmChannelValue, getExpirationTime(expirationInMinutes), '/', location.hostname, false);
	}
})();
